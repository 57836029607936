// src/components/LoginModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Divider,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// Añadir iconos a la librería de Font Awesome
library.add(faLock, faUser, faEnvelope);

const LoginModal = ({ open, onClose }) => {
  const [currentView, setCurrentView] = useState('login'); // 'login', 'register', 'reset'
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Estados para el formulario de inicio de sesión
  const [loginFormData, setLoginFormData] = useState({
    username_or_email: '',
    password: '',
  });
  const [loginFeedback, setLoginFeedback] = useState('');

  // Estados para el formulario de registro
  const [registerFormData, setRegisterFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    gdpr_consent: false,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [registerFeedback, setRegisterFeedback] = useState('');

  // Estados para el formulario de restablecimiento de contraseña
  const [resetFormData, setResetFormData] = useState({
    email: '',
  });
  const [resetFeedback, setResetFeedback] = useState('');

  // Manejo de cambios en el formulario de inicio de sesión
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };

  // Manejo de envío del formulario de inicio de sesión
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginFeedback('');
    setLoading(true);

    try {
      const response = await api.post('/login', {
        username_or_email: loginFormData.username_or_email,
        password: loginFormData.password,
      });
      const { session_token } = response.data;
      localStorage.setItem('session_token', session_token);
      toast.success('¡Inicio de sesión exitoso!');
      onClose(); // Cierra el modal de inicio de sesión
      navigate('/home');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setLoginFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setLoginFeedback('Error al iniciar sesión.');
        toast.error('Error al iniciar sesión.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Manejo de cambios en el formulario de registro
  const handleRegisterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegisterFormData({
      ...registerFormData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Manejo de envío del formulario de registro
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setRegisterFeedback('');
    setLoading(true);
    if (registerFormData.password !== registerFormData.confirm_password) {
      setRegisterFeedback('Las contraseñas no coinciden.');
      toast.error('Las contraseñas no coinciden.');
      setLoading(false);
      return;
    }
    try {
      const response = await api.post('/register', {
        username: registerFormData.username,
        email: registerFormData.email,
        password: registerFormData.password,
        gdpr_consent: registerFormData.gdpr_consent,
        time_zone: registerFormData.time_zone,
      });
      toast.success('¡Registro exitoso! Revisa tu correo para confirmar tu cuenta.');
      // Resetear el formulario de registro
      setRegisterFormData({
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        gdpr_consent: false,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      // Cambiar al modo de inicio de sesión
      setCurrentView('login');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setRegisterFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setRegisterFeedback('Error al registrarse.');
        toast.error('Error al registrarse.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Manejo de cambios en el formulario de restablecimiento de contraseña
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setResetFormData({
      ...resetFormData,
      [name]: value,
    });
  };

  // Manejo de envío del formulario de restablecimiento de contraseña
  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setResetFeedback('');
    setLoading(true);

    try {
      const response = await api.post('/reset_password', {
        email: resetFormData.email,
      });
      toast.success('¡Correo de restablecimiento enviado! Revisa tu bandeja de entrada.');
      // Resetear el formulario de restablecimiento
      setResetFormData({
        email: '',
      });
      // Cambiar al modo de inicio de sesión
      setCurrentView('login');
    } catch (error) {
      if (error.response && error.response.data.error) {
        setResetFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setResetFeedback('Error al enviar el correo de restablecimiento.');
        toast.error('Error al enviar el correo de restablecimiento.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Cambio de vista
  const switchView = (view) => {
    setCurrentView(view);
    // Resetear feedback y formularios
    setLoginFeedback('');
    setRegisterFeedback('');
    setResetFeedback('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: 'center', position: 'relative', paddingBottom: '10px' }}>
        {currentView === 'login' && 'Iniciar Sesión'}
        {currentView === 'register' && 'Registrarse'}
        {currentView === 'reset' && 'Restablecer Contraseña'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '20px' }}>
        {currentView === 'login' && (
          <Box>
            <form onSubmit={handleLoginSubmit}>
              <TextField
                label="Correo electrónico o nombre de usuario"
                name="username_or_email"
                value={loginFormData.username_or_email}
                onChange={handleLoginChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="user" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Contraseña"
                type="password"
                name="password"
                value={loginFormData.password}
                onChange={handleLoginChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />

              {loginFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {loginFeedback}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Iniciar Sesión'}
              </Button>
            </form>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Button
                onClick={() => switchView('reset')}
                sx={{ textTransform: 'none', color: '#1976d2' }}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </Typography>

            <Divider sx={{ my: 3 }}>O</Divider>

            {/* Enlace para Registro */}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿No tienes una cuenta?{' '}
              <Button
                onClick={() => switchView('register')}
                sx={{ textTransform: 'none', color: '#1976d2' }}
              >
                Regístrate aquí
              </Button>
            </Typography>
          </Box>
        )}

        {currentView === 'register' && (
          <Box>
            <form onSubmit={handleRegisterSubmit}>
              <TextField
                label="Nombre de usuario"
                name="username"
                value={registerFormData.username}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="user" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Correo electrónico"
                name="email"
                type="email"
                value={registerFormData.email}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="envelope" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Contraseña"
                type="password"
                name="password"
                value={registerFormData.password}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Confirmar Contraseña"
                type="password"
                name="confirm_password"
                value={registerFormData.confirm_password}
                onChange={handleRegisterChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="lock" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={registerFormData.gdpr_consent}
                    onChange={handleRegisterChange}
                    name="gdpr_consent"
                    required
                    color="primary"
                  />
                }
                label={
                  <>
                    Acepto los{' '}
                    <a
                      href="https://www.facemyai.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#1976d2' }}
                    >
                      términos y condiciones
                    </a>
                  </>
                }
              />

              {registerFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {registerFeedback}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Registrarse'}
              </Button>
            </form>

            {/* Enlace para Regresar al Login */}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿Ya tienes una cuenta?{' '}
              <Button
                onClick={() => switchView('login')}
                sx={{ textTransform: 'none', color: '#1976d2' }}
              >
                Inicia Sesión aquí
              </Button>
            </Typography>
          </Box>
        )}

        {currentView === 'reset' && (
          <Box>
            <form onSubmit={handleResetSubmit}>
              <TextField
                label="Correo electrónico"
                name="email"
                type="email"
                value={resetFormData.email}
                onChange={handleResetChange}
                fullWidth
                margin="normal"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="envelope" color="#9e9e9e" />
                    </InputAdornment>
                  ),
                }}
              />

              {resetFeedback && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {resetFeedback}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 2,
                  borderRadius: '25px',
                  padding: '10px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar Correo de Restablecimiento'}
              </Button>
            </form>

            {/* Enlace para Regresar al Login */}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              ¿Recuerdas tu contraseña?{' '}
              <Button
                onClick={() => switchView('login')}
                sx={{ textTransform: 'none', color: '#1976d2' }}
              >
                Inicia Sesión aquí
              </Button>
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
