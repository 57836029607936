// src/components/Assistant/Conversations.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  useMediaQuery,
  Divider,
  Container,
  Button,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { styled } from '@mui/material/styles';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Estilos personalizados de mensajes
const MessageContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
}));

// Colores suaves para roles
const userBgColor = '#d0f8ce';       // verde claro
const assistantBgColor = '#dcecfb';  // azul claro

const UserMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: userBgColor,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '75%',
  alignSelf: 'flex-end', // Usuario a la derecha
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const AssistantMessage = styled(Paper)(({ theme }) => ({
  backgroundColor: assistantBgColor,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '75%',
  alignSelf: 'flex-start', // Asistente a la izquierda
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: '#666',
  marginTop: theme.spacing(0.5),
}));

const Conversations = () => {
  const { assistantId, conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const response = await api.get(`/assistant/${assistantId}/conversation/${conversationId}`);
        if (response.status === 200) {
          const { metadata } = response.data;
          let convoMessages = metadata || [];
          setMessages(convoMessages);
        } else {
          console.error('Error al obtener la conversación');
        }
      } catch (error) {
        console.error('Error al obtener la conversación:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [assistantId, conversationId]);

  if (loading) {
    return (
      <Box
        className="loading-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress aria-label="Cargando conversación" />
      </Box>
    );
  }

  return (
    <Box className="main-wrapper" sx={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <SidebarCreateAsistente />
      <Box component="main" className="main-content" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom tabIndex={0}>
              Historial de Conversación #{conversationId}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={() => navigate(`/assistant/${assistantId}/details`)}
              aria-label="Volver a detalles del asistente"
            >
              Volver
            </Button>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {messages && messages.length > 0 ? (
            <Box
              sx={{
                maxHeight: '70vh',
                overflowY: 'auto',
                pr: 1,
              }}
            >
              {messages.map((msg) => (
                <MessageContainer
                  key={msg.id}
                  sx={{
                    alignItems: msg.role === 'user' ? 'flex-end' : 'flex-start'
                  }}
                >
                  {msg.role === 'user' ? (
                    <UserMessage elevation={1}>
                      <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                        👤 {msg.content}
                      </Typography>
                      {msg.created_at && (
                        <Timestamp align="right">{new Date(msg.created_at * 1000).toLocaleString()}</Timestamp>
                      )}
                    </UserMessage>
                  ) : (
                    <AssistantMessage elevation={1}>
                      <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                        🤖 {msg.content}
                      </Typography>
                      {msg.created_at && (
                        <Timestamp align="left">{new Date(msg.created_at * 1000).toLocaleString()}</Timestamp>
                      )}
                    </AssistantMessage>
                  )}
                </MessageContainer>
              ))}
            </Box>
          ) : (
            <Typography variant="h6">No hay mensajes en esta conversación.</Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Conversations;
