// src/components/Assistant/AssistantDetails.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CircularProgress,
  Paper,
  useMediaQuery,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Tooltip,
  Button,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Estilo para la tarjeta
const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
  },
  borderRadius: theme.shape.borderRadius,
}));

const AssistantDetails = () => {
  const { assistantId } = useParams();
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [filterDate, setFilterDate] = useState('');
  const [sortOrder, setSortOrder] = useState('desc'); // desc más recientes primero

  const fetchConversations = async () => {
    try {
      const response = await api.get(`/assistant/${assistantId}/conversations`);
      if (response.status === 200) {
        setConversations(response.data.conversations);
        setFilteredConversations(response.data.conversations);
      } else {
        console.error('Error al obtener conversaciones');
      }
    } catch (error) {
      console.error('Error al obtener conversaciones:', error);
      toast.error('Error al obtener las conversaciones');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [assistantId]);

  const handleDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchConversations();
  };

  // Filtrar y ordenar
  useEffect(() => {
    let updated = [...conversations];
    // Filtrar por fecha
    if (filterDate) {
      updated = updated.filter((conv) => {
        if (!conv.LastMessageDate) return false;
        const convDate = format(new Date(conv.LastMessageDate), 'yyyy-MM-dd');
        return convDate === filterDate;
      });
    }

    // Ordenar
    updated.sort((a, b) => {
      const dateA = a.LastMessageDate ? new Date(a.LastMessageDate) : new Date(0);
      const dateB = b.LastMessageDate ? new Date(b.LastMessageDate) : new Date(0);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    setFilteredConversations(updated);
  }, [conversations, filterDate, sortOrder]);

  if (loading) {
    return (
      <Box
        className="loading-container"
        role="status"
        aria-live="polite"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress aria-label="Cargando conversaciones" />
      </Box>
    );
  }

  return (
    <Box className="main-wrapper" sx={{ opacity: loading ? 0.5 : 1, transition: 'opacity 0.3s ease-in-out' }}>
      <SidebarCreateAsistente />
      <Box component="main" className="main-content" sx={{ p: 2 }}>
        <Container maxWidth="lg">
          <Box
            className="header-container"
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            justifyContent="space-between"
            mb={2}
            gap={2}
          >
            <Typography variant={isSmallScreen ? 'h5' : 'h4'} gutterBottom tabIndex={0}>
              Conversaciones del asistente {assistantId}
            </Typography>
          </Box>

          {/* Filtros */}
          <Box className="filters-container" mb={3} display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2}>
            <Box flex={1}>
              <Tooltip title="Filtrar por fecha de la última respuesta" arrow>
                <TextField
                  type="date"
                  label="Filtrar por Fecha"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={filterDate}
                  onChange={handleDateChange}
                  aria-label="Filtrar por fecha"
                  InputProps={{
                    startAdornment: <CalendarTodayIcon color="action" />,
                    style: { borderColor: filterDate ? '#2196f3' : undefined }
                  }}
                />
              </Tooltip>
            </Box>

            <Box flex={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="sort-label">Ordenar Por</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sortOrder}
                  onChange={handleSortChange}
                  label="Ordenar Por"
                  aria-label="Ordenar conversaciones"
                  startAdornment={<SortIcon color="action" />}
                >
                  <MenuItem value="desc">Más Recientes Primero</MenuItem>
                  <MenuItem value="asc">Más Antiguas Primero</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Tooltip title="Refrescar" arrow>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRefresh}
                  startIcon={<RefreshIcon />}
                  aria-label="Refrescar conversaciones"
                >
                  Refrescar
                </Button>
              </Tooltip>
            </Box>
          </Box>

          {/* Lista de Conversaciones */}
          {filteredConversations.length > 0 ? (
            <Grid container spacing={2}>
              {filteredConversations.map((conversation) => (
                <Grid item xs={12} sm={6} md={4} key={conversation.ConversationID}>
                  <Paper elevation={3} sx={{ cursor: 'pointer' }} aria-label={`Ver conversación con ${conversation.UserName}`}>
                    <CardActionArea
                      onClick={() =>
                        navigate(`/assistant/${assistantId}/conversation/${conversation.ConversationID}`)
                      }
                      onFocus={(e) => e.currentTarget.style.background = '#f0f0f0'}
                      onBlur={(e) => e.currentTarget.style.background = 'transparent'}
                      onMouseEnter={(e) => e.currentTarget.style.background = '#f7f7f7'}
                      onMouseLeave={(e) => e.currentTarget.style.background = 'transparent'}
                    >
                      <StyledCard>
                        <CardContent>
                          <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            Usuario: {conversation.UserName}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            Fecha del último mensaje:{' '}
                            {conversation.LastMessageDate
                              ? format(
                                  new Date(conversation.LastMessageDate),
                                  'dd/MM/yyyy HH:mm:ss'
                                )
                              : 'N/A'}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            Número de mensajes: {conversation.MessageCount}
                          </Typography>
                          <Box mt={1} display="flex" alignItems="center" gap={1}>
                            <Typography variant="body2" color="primary">
                              Ver Conversación
                            </Typography>
                            <ArrowForwardIosIcon fontSize="small" />
                          </Box>
                        </CardContent>
                      </StyledCard>
                    </CardActionArea>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box textAlign="center" mt={5}>
              <Typography variant="h6" gutterBottom>
                No hay conversaciones que coincidan con los filtros seleccionados.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Prueba seleccionando otra fecha u orden, o refresca la página.
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default AssistantDetails;
